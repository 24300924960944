<template>
  <div>
    <page-title :title="`Request Quote`" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="request-quote" v-if="step == 1">
              <form @submit.prevent="requestQuote">
                <h3>Request a Bidbond Quote</h3>
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Tenure Duration</label>
                      <vue-select
                        :options="tenure_durations"
                        label="name"
                        placeholder="Select Tenure Duration"
                        v-model="tenure_duration"
                        required="required"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            :required="!tenure_duration"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </vue-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Counter Party</label>
                      <vue-select
                        :options="counter_parties"
                        label="name"
                        placeholder="Select Couter Party"
                        v-model="counter_party"
                        required="required"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            :required="!counter_party"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </vue-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Bid Amount KES</label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="quote.amount"
                        placeholder="Enter Bidbond Amount"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Your Email</label>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Email"
                        required
                        v-model="quote.email"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <button class="btn btn-primary">Request Quote</button>
                  </div>
                </div>
              </form>
            </div>
            <div class="request-quote" v-if="step == 2">
              <div class="quote-sent">
                <h3>Quote Sent.</h3>
                <hr />
                <p>
                  Your quote for
                  <strong>KES {{ $number.format(quote.amount) }}</strong>
                  has been sent to your email {{ quote.email }}
                </p>
                <br />
                <p>Thank You.</p>
                <br />
                <router-link to class="btn btn-success">Generate Bidbond</router-link>
                <a href="#" @click.prevent="step = 1" class="btn btn-secondary float-right">Back</a>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tenure_periods from "@/data/tenure-periods";

export default {
  name: "create-quote",

  mounted() {
    this.$axios.get("/api/v1/dashboard/counterparties").then(response => {
      this.counter_parties = response.data;
    });
  },

  data() {
    return {
      step: 1,
      quote: {
        email: "",
        amount: "",
        phone_number: ""
      },
      tenure_durations: tenure_periods.data,
      counter_parties: [],
      counter_party: "",
      tenure_duration: ""
    };
  },

  methods: {
    requestQuote() {
      let data = {
        amount: this.quote.amount,
        counterparty: this.counter_party.id,
        email: this.quote.email,
        tenure: this.tenure_duration.value
      };

      this.$axios
        .post("/api/v1/dashboard/quotes", data)
        .then(() => {
          this.step = 2;
        })
        .catch(error => {
          this.$http(error.response);
        });
    }
  }
};
</script>